<template>
    <div class="hello">
        <div class="popup-box" :style="boxPositon=='up'?'bottom:-350px':'bottom:0px'">
            <div class="popup-box_up" @click="setPosition">
                <span>向上</span>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
        name: "Demo",
        data() {
            return {
                boxPositon:"up"
                
            }
        },
        mounted() {
            
        },

        methods: {
            setPosition(){
                if(this.boxPositon == "up"){
                    this.boxPositon = "down";
                }else{
                    this.boxPositon = "up";
                }

            }
            
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.hello{
    width: 100%;
    height: 100vh;
    position: relative;
    .popup-box{
        position: absolute;
        bottom: -350px;
        width: 100%;
        height: 500px;
        background-color: red;
        .popup-box_up{
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
